import AddIcon from "@mui/icons-material/Add";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElementId,
  setSelectedTake,
} from "../../app/slices/TrainingSlice";
import usePropHelper from "../../hooks/PropHelper";
import Take3D from "../../models/Take3D";
import TakeActorList from "../actor_settings/TakeActorList";
import InteractionPicker from "../interaction_picker/InteractionPicker";
import PropTakeList from "../prop_take_list/PropTakeList";
import InteractionCard from "./InteractionCard";
import "./TakeCard3DContent.css";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
export default function TakeCard3DContent(props: {
  take: Take3D;
  index: number;
}) {
  const { take } = { ...props };
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getInvolvedPropInteractions } = usePropHelper();
  const { t } = useTranslation();
  const interactionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const selectedElement = useSelector(selectSelectedElementId);

  const { setNodeRef: droppableRef } = useDroppable({
    id: take.id,
  });

  useEffect(() => {
    if (selectedElement && interactionRefs.current[selectedElement]) {
      interactionRefs.current[selectedElement]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedElement]);

  const rootClassname = take.isExpanded
    ? "takecard3dcontent-root takecard3dcontent-expanded"
    : "takecard3dcontent-root takecard3dcontent-collapsed";

  const onClickAddInteraction = () => {
    dispatch(setSelectedTake(take.id));
    setIsOpen(true);

    // TODO: set prop interactions !
    let involvedInteractions = getInvolvedPropInteractions(take);
  };

  const interactionTitle = (
    <div className="flex gap-[10px] p-[10px] text-creator-text text-[24px] bg-white border-b-2 border-solid border-creator-border">
      <LayersOutlinedIcon
        className={"my-auto"}
        color={"inherit"}
        fontSize={"inherit"}
      />
      <div className={"text-[14px] text-creator-text font-bold"}>
        {t("interactions")}
      </div>
    </div>
  );

  const interactionsDnd = (
    <div>
      <SortableContext
        items={take.interactions.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
        id={take.id}
      >
        <div ref={droppableRef} className="min-h-[16px] bg-white">
          {take.interactions.map((interaction, index) => (
            <div key={index} className="take3dcardcontent-interaction">
              <InteractionCard interaction={interaction} />
            </div>
          ))}
        </div>
      </SortableContext>
    </div>
  );

  const interactionContent = <div className="">{interactionsDnd}</div>;

  const addInteractionIcon = (
    <div
      className={
        take.interactions.length > 0
          ? "take3dcardcontent-addinteraction take3dcardcontent-addinteraction-border"
          : "take3dcardcontent-addinteraction"
      }
    >
      <div className="take3dcardcontent-addinteraction-button">
        <IconButton
          color="inherit"
          size="small"
          onClick={onClickAddInteraction}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );

  const interactions = (
    <>
      {interactionTitle}
      {interactionContent}
      {addInteractionIcon}
    </>
  );

  return (
    <>
      <div className={rootClassname}>{interactions}</div>
      <InteractionPicker open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
