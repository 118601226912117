import Interaction from "../../models/interactions/Interaction";
import Take360 from "../../models/Take360";

export function isValidTake360(take360: Take360) {
  return (
    take360 &&
    typeof take360.id === "string" &&
    typeof take360.interactions === "object" &&
    typeof take360.isExpanded === "boolean" &&
    typeof take360.name === "string" &&
    typeof take360.automaticTransition === "boolean" &&
    typeof take360.display360 === "boolean"
  );
}

export function isValidInteraction(interaction: Interaction) {
  return (
    interaction &&
    typeof interaction.type === "string" &&
    typeof interaction.id === "string" &&
    typeof interaction.name === "string"
  );
}
