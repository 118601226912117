import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  deleteTake,
  setSelectedTake,
  updateSelectedTake,
} from "../../app/slices/TrainingSlice";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import Take3D from "../../models/Take3D";
import PiMenuButton from "../menu/PiMenuButton";
import classNames from "classnames";

export const Take3DCardHeader = forwardRef<
  HTMLDivElement,
  {
    take: Take3D;
    isSelected: boolean;
  }
>(({ take, isSelected, ...props }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const onToggleExpand = () => {
    const cpy: Take3D = { ...take, isExpanded: !take.isExpanded };
    dispatch(updateSelectedTake(cpy));
  };

  const onClick = () => {
    dispatch(setSelectedTake(take.id));
  };

  const DeleteBubble = () => {
    dispatch(deleteTake(take.id));
  };

  const icon = (
    <div
      className={classNames({
        "flex my-auto text-[22px]": true,
        "text-creator-primary": isSelected,
        "text-creator-text-sub": !isSelected,
      })}
    >
      <MovieCreationOutlinedIcon className={"my-auto"} fontSize={"inherit"} />
    </div>
  );

  const arrow = (
    <div
      className={classNames({
        "flex my-auto text-[20px] text-creator-text !cursor-pointer": true,
        "transition-transform rotate-180": take.isExpanded,
        "transition-transform rotate-0": !take.isExpanded,
      })}
      onClick={onToggleExpand}
    >
      <KeyboardArrowDownIcon
        className={"my-auto"}
        color={"inherit"}
        fontSize={"inherit"}
      />
    </div>
  );

  const name = (
    <div
      className={classNames({
        "w-full my-auto text-[14px] truncate": true,
        "text-creator-primary font-bold": isSelected,
        "text-creator-text-sub font-medium": !isSelected,
      })}
    >
      {take.name}
    </div>
  );

  const menu = (
    <div className={"my-auto"}>
      <PiPopoverMenu>
        <PiMenuButton text={t("rename")} disabled />
        <PiMenuButton
          text={t("delete")}
          onClick={() => setIsDeleteDialogOpen(true)}
        />
      </PiPopoverMenu>
    </div>
  );

  return (
    <div
      className={"flex gap-[10px] !h-[56px] w-full p-[10px]"}
      onClick={onClick}
      {...props}
    >
      {arrow}
      {icon}
      {name}
      {menu}
      <PiConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={DeleteBubble}
        text={t("delete_bubble.delete_description")}
        title={t("delete_bubble.delete_title")}
        confirmText={t("delete_bubble.confirm_delete")}
        cancelText={t("delete_bubble.cancel_delete")}
        height="215px"
        confirmButtonVariant="piRed"
      />
    </div>
  );
});
