import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Interaction from "../../models/interactions/Interaction";
import Take360 from "../../models/Take360";
import "./Take360CardContent.css";

import {
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElementId,
  selectTakes360,
  setSelectedTake,
} from "../../app/slices/TrainingSlice";
import InteractionPicker from "../interaction_picker/InteractionPicker";
import InteractionCard from "./InteractionCard";
import TeleportMarkerCard from "./TeleportMarkerCard";
import { OnboardingService } from "../onboarding/OnboardingService";
import { OnboardingContext } from "../onboarding/OnboardingContext";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
interface Take360CardContentProps {
  take: Take360;
  isExpanded: boolean;
}

export default function Take360CardContent(props: Take360CardContentProps) {
  const dispatch = useDispatch();
  const { take, isExpanded } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const interactionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const selectedElement = useSelector(selectSelectedElementId);

  // hack to make addFirstBubble onboarding work
  const allTakes = useSelector(selectTakes360);
  const isLast = allTakes[allTakes.length - 1].id === take.id;

  useEffect(() => {
    if (selectedElement && interactionRefs.current[selectedElement]) {
      interactionRefs.current[selectedElement]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedElement]);

  const interactions = take.interactions.map(
    (interaction: Interaction, index: number) => {
      return <InteractionCard key={index} interaction={interaction} />;
    }
  );

  const onClickAddInteraction = () => {
    dispatch(setSelectedTake(take.id));
    setIsOpen(true);
  };

  const expandedClassnames = isExpanded
    ? "take360cardcontent-expanded"
    : "take360cardcontent-folded";

  const addInteractionClasses =
    interactions.length > 0
      ? "take360cardcontent-addinteraction take360cardcontent-addinteraction-border"
      : "take360cardcontent-addinteraction";

  const { setNodeRef: droppableRef } = useDroppable({
    id: take.id,
  });

  const interactionsdnd = (
    <div>
      <SortableContext
        items={take.interactions.map((i) => i.id)}
        id={take.id}
        strategy={verticalListSortingStrategy}
      >
        <div ref={droppableRef} className="min-h-[16px] bg-white">
          {take.interactions.map((interaction, index) => (
            <div key={index}>
              <InteractionCard interaction={interaction} />
            </div>
          ))}
        </div>
      </SortableContext>
    </div>
  );

  const addInteractionIcon = (
    <div className={addInteractionClasses}>
      <div
        className="take360cardcontent-addinteraction-button"
        id={isLast ? "addInteractionButton-last" : ""}
      >
        <IconButton
          color="inherit"
          size="small"
          onClick={onClickAddInteraction}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );

  return (
    <div className={"take360cardcontent-root " + expandedClassnames}>
      <div>{interactionsdnd}</div>
      {addInteractionIcon}
      <TeleportMarkerCard interaction={take.teleportMarker} />
      <InteractionPicker open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}
