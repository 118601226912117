import { useDispatch } from "react-redux";
import { updateSelectedTake } from "../../app/slices/TrainingSlice";
import Take360 from "../../models/Take360";
import "./Take360Card.css";
import Take360CardContent from "./Take360CardContent";
import { TakeCard360Header } from "./TakeCard360Header";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface TakeCardProps {
  take: Take360;
  isSelected: boolean;
}

export default function Take360Card(props: TakeCardProps) {
  const { take, isSelected } = { ...props };
  const dispatch = useDispatch();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: take.id,
      transition: {
        duration: 150, // milliseconds
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isSelectedClasses = isSelected
    ? "take360card-selected"
    : "take360card-not-selected";

  const toggleExpanded = () => {
    var updatedTake: Take360 = { ...props.take };
    updatedTake.isExpanded = !updatedTake.isExpanded;
    dispatch(updateSelectedTake(updatedTake));
  };

  return (
    <div
      className={"take360card-root " + isSelectedClasses}
      ref={setNodeRef}
      style={style}
    >
      <TakeCard360Header
        take={take}
        isSelected={isSelected}
        onToggleExpand={toggleExpanded}
        {...attributes}
        {...listeners}
      />
      <Take360CardContent isExpanded={take.isExpanded} take={take} />
    </div>
  );
}
