import { DragOverEvent, DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  reorderInteractions,
  reorderTakes,
  selectSelectedTake3D,
  selectTakes3D,
  setSelectedInteraction,
  setSelectedTake,
  setTakes,
} from "../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";
import Take3DCard from "./Take3DCard";
import InteractionCard from "../training_timeline/InteractionCard";

export default function useTimeline3DTakesList() {
  const takes = useSelector(selectTakes3D);
  const selectedTake = useSelector(selectSelectedTake3D);
  const dispatch = useDispatch();

  const [activeId, setActiveId] = useState<string | null>(null);
  const [draggingTake, setDraggingTake] = useState<boolean | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id.toString());

    if (takes.some((take) => take.id === event.active.id)) {
      // select the take we are dragging to selected
      dispatch(setSelectedTake(event.active.id.toString()));
      setDraggingTake(true);
    } else {
      // set the interaction we are dragging to selected
      dispatch(setSelectedInteraction(event.active.id.toString()));
      setDraggingTake(false);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    // if we are dragging takes, re-order the takes
    if (draggingTake) {
      // calculate indices
      const oldIndex = takes.findIndex((take) => take.id === active.id);
      const newIndex = takes.findIndex((take) => take.id === over.id);

      // dispatch to slice
      dispatch(reorderTakes({ startIndex: oldIndex, endIndex: newIndex }));
    }
    // if we are dragging interactions, re-order them
    else {
      // container id -> the take we are dragging over
      const containerId = event.active.data.current?.sortable.containerId;
      const take = takes.find((take) => take.id === containerId);
      if (!take) return;

      // calculate indices
      const oldIndex = take.interactions.findIndex((i) => i.id === active.id);
      const newIndex = take.interactions.findIndex((i) => i.id === over.id);

      // dispatch to slice
      dispatch(
        reorderInteractions({
          startTakeId: take.id,
          destinationTakeId: take.id,
          startIndex: oldIndex,
          endIndex: newIndex,
        })
      );
    }

    setActiveId(null);
    setDraggingTake(null);
  };

  // used to handle dragging from one take to another
  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    // we no active id or we are dragging a take, return
    // we only need this function for interactions
    if (!activeId || draggingTake) return;
    if (!over || !active.data.current) return;

    const sourceTakeId: string = active.data.current?.sortable.containerId;
    let destinationTakeId: string = over.data.current?.sortable.containerId;

    // we are overing an empty droppable
    if (destinationTakeId === "takes3d") {
      destinationTakeId = over.id.toString();
    }

    // we only need to do something if the source and destination takes are not the same
    if (
      sourceTakeId &&
      destinationTakeId &&
      sourceTakeId !== destinationTakeId
    ) {
      // remove the interaction from the source take
      // add the interaction to the destination take
      // dispatch the changes
      const takesCopy = [...takes];
      const sourceIndex = takesCopy.findIndex(
        (take) => take.id === sourceTakeId
      );
      const destinationIndex = takesCopy.findIndex(
        (take) => take.id === destinationTakeId
      );

      // sometimes we are in between takes, so we will not find a destination
      if (destinationIndex === -1) return;

      const sourceTake = {
        ...takesCopy[sourceIndex],
      };
      const destinationTake = {
        ...takesCopy[destinationIndex],
      };

      const interaction = {
        ...sourceTake.interactions.find((i) => i.id === activeId)!,
      };

      sourceTake.interactions = [
        ...sourceTake.interactions.filter((i) => i.id !== activeId),
      ];
      destinationTake.interactions = [
        interaction!,
        ...destinationTake.interactions,
      ];

      takesCopy[sourceIndex] = sourceTake;
      takesCopy[destinationIndex] = { ...destinationTake, isExpanded: true }; // force expand the destination take

      // dispatch
      dispatch(setTakes(takesCopy));
      dispatch(setSelectedTake(destinationTakeId));
    }
  };

  const getDragOverlay = () => {
    if (!activeId) return <></>;

    if (draggingTake) {
      const take = takes.find((take) => take.id === activeId);
      if (!take) return <></>;
      return <Take3DCard take={take} index={-1} isSelected={false} ghost />;
    } else {
      const interaction = selectedTake?.interactions.find(
        (interaction) => interaction.id === activeId
      );
      if (!interaction) return <></>;
      return <InteractionCard interaction={interaction} ghost />;
    }
  };
  return {
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    getDragOverlay,
  } as const;
}
