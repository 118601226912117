import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilteredTakes,
  selectSelectedTakeId,
} from "../../app/slices/TrainingSlice";
import Take360Card from "./Take360Card";
import "./TrainingTakeList.css";
import useDelay from "../../hooks/Delay.hook";
import { DndContext, DragOverlay, MouseSensor, useSensor } from "@dnd-kit/core";
import useTrainingTakeList from "./TrainingTakeList.hook";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
export default function TrainingTakeList() {
  const takes = useSelector(selectFilteredTakes);
  const selectedTakeId = useSelector(selectSelectedTakeId);
  const dispatch = useDispatch();
  const takeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { delay } = useDelay();

  const { handleDragStart, handleDragEnd, handleDragOver, getDragOverlay } =
    useTrainingTakeList();

  // if we don't use this, dndkit blocks the "onClick" event
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const scrollAsync = async (takeId: string) => {
    await delay(300);
    if (selectedTakeId && takeRefs.current[takeId]) {
      takeRefs.current[takeId]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    scrollAsync(selectedTakeId);
  }, [selectedTakeId]);

  const takesviewdnd = (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      sensors={[mouseSensor]}
    >
      <div className="flex flex-col gap-[10px]">
        <SortableContext
          items={takes.map((take) => take.id)}
          strategy={verticalListSortingStrategy}
          id={"takes360"}
        >
          {takes.map((take, index) => (
            <Take360Card
              key={take.id}
              take={take}
              isSelected={take.id === selectedTakeId}
            />
          ))}
        </SortableContext>
      </div>
      <DragOverlay>{getDragOverlay()}</DragOverlay>
    </DndContext>
  );

  return (
    <div className="flex flex-col h-auto overflow-y-auto pit-creator-scrollbar px-[18px] pt-[18px]">
      {takesviewdnd}
    </div>
  );
}
