import { useSortable } from "@dnd-kit/sortable";
import Take3D from "../../models/Take3D";
import TakeCard3DContent from "../training_timeline/TakeCard3DContent";
import "./Take3DCard.css";
import { Take3DCardHeader } from "./Take3DCardHeader";
import classNames from "classnames";
import { CSS } from "@dnd-kit/utilities";

export default function Take3DCard(props: {
  take: Take3D;
  index: number;
  isSelected: boolean;
  ghost?: boolean;
}) {
  const { take, isSelected, ghost } = { ...props };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: take.id,
      transition: {
        duration: 150, // milliseconds
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      className={classNames({
        "border-2 border-solid box-border rounded-[5px] hover:border-creator-primary-hover bg-white":
          true,
        "border-creator-primary": isSelected,
        "border-creator-border": !isSelected,
        "opacity-75": ghost,
      })}
      ref={setNodeRef}
      style={style}
    >
      <Take3DCardHeader {...props} {...attributes} {...listeners} />
      <TakeCard3DContent {...props} />
    </div>
  );
}
