import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SelectedTab,
  selectIsPropertiesColumnOpen,
  selectSelectedTab,
} from "../../app/slices/GlobalSlice";
import {
  selectIsDirty,
  selectIsPlaying,
  selectTrainingType,
} from "../../app/slices/TrainingSlice";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";
import { useLoadTraining } from "../../hooks/LoadTrainingHook";
import AdvancedSearchColumn from "../advanced_search_column/AdvancedSearchColumn";
import AutoOnboarding from "../onboarding/AutoOnboarding";
import PropertiesColumn from "../properties_column/PropertiesColumn";
import TrainingActionbar from "../training_actionbar/TrainingActionbar";
import {
  TrainingEditor360,
  TrainingEditor3D,
  TrainingEditorNotSet,
} from "../training_preview/TrainingEditorStrategy";
import Preview from "../training_preview/TrainingPreview";
import TrainingTimeline from "../training_timeline/TrainingTimeline";
import "./TrainingEditor.css";
import TrainingEditorMenu from "./TrainingEditorMenu";
import useValidation from "../validation/Validation.hooks";
import useDelay from "../../hooks/Delay.hook";

export default function TrainingEditor() {
  const isDirty = useSelector(selectIsDirty);
  const { t } = useTranslation();
  const { trainingId } = useParams();
  const [loadTraining] = useLoadTraining();
  const navigate = useNavigate();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const trainingType = useSelector(selectTrainingType);
  const isPropertiesColumnOpen = useSelector(selectIsPropertiesColumnOpen);
  const selectedTab = useSelector(selectSelectedTab);
  const isPlaying = useSelector(selectIsPlaying);
  const trainingStrategy = useMemo(() => {
    if (trainingType === 0) return TrainingEditor3D;
    else if (trainingType === 1) return TrainingEditor360;
    else return TrainingEditorNotSet;
  }, [trainingType]);

  const { delay } = useDelay();
  const { validateArrayOfTakes } = useValidation();

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue =
        "All unsaved changes will be lost. Are you sure you want to leave?";
    }
  };

  const LoadTrainingPage = async () => {
    if (!trainingId) return;

    var loadSuccesful = await loadTraining(trainingId);
    if (!loadSuccesful) {
      setErrorDialogOpen(true);
    }
  };

  const ExitEditorOnError = () => {
    navigate("/");
  };

  useEffect(() => {
    LoadTrainingPage();
  }, [trainingId]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  return (
    <>
      <div className="h-[100vh] max-h-[100vh] relative overflow-y-clip w-[100%] min-w-[1200px]">
        <TrainingEditorMenu />
        <TrainingActionbar />
        <div className="relative trainingeditor-content">
          <TrainingTimeline strategy={trainingStrategy} />
          <AdvancedSearchColumn />
          <Preview
            isVisible={selectedTab === SelectedTab.Timeline}
            strategy={trainingStrategy}
          />
          {/*{selectedTab === SelectedTab.Timeline && }*/}
          {!isPlaying && <PropertiesColumn />}
        </div>
      </div>

      <AutoOnboarding context="trainingEditor" />
      <PiConfirmationDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title={t("load_training_failed.title")}
        text={t("load_training_failed.description") + " " + trainingId}
        onConfirm={ExitEditorOnError}
        confirmText={t("load_training_failed.confirm")}
        cancelText=""
        confirmButtonVariant="piRed"
        hideCancelButton
        height="200px"
      />
    </>
  );
}
