import Interaction from "../../models/interactions/Interaction"
import Take3D from "../../models/Take3D"

export function isValidTake3D(take3D: Take3D) {
    return take3D &&
        typeof take3D.id === 'string' &&
        typeof take3D.actors === 'object' &&
        typeof take3D.interactions === 'object' &&
        typeof take3D.isExpanded === 'boolean' &&
        typeof take3D.mainCamera === 'object' &&
        typeof take3D.name === 'string' &&
        typeof take3D.props === 'object'
}

export function isValidInteraction(interaction: Interaction) {
    return interaction &&
        typeof interaction.type === "string" &&
        typeof interaction.id === "string" &&
        typeof interaction.name === "string"
}
